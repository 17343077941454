@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #fff;
  line-height: 1.5em;
  font-weight: 400;
  background: url(/static/media/background.2f41e34d.jpg) 50% center / cover no-repeat rgb(25, 25, 25);
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #3bb2e1;
}

a:hover,
a:focus,
a:visited,
a:active,
button:active,
button:focus {
  text-decoration: none;
  outline: none;
}

ul,
ul li,
ol,
ol li {
  list-style: none;
  margin: 0 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: 600;
  margin: 0 0 15px;
  background: none;
  color: #010101;
  line-height: 1.30em;
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

input,
button,
select,
textarea {
  font-family: 'Poppins', sans-serif;
}

textarea {
  overflow: hidden;
  resize: none;
}

p {
  position: relative;
  line-height: 1.50em;
  margin-bottom: 20px;
}

.text {
  position: relative;
  line-height: 1.50em;
  margin-bottom: 0px;
}

.strike-through {
  text-decoration: line-through;
}

.main-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0px 0px;
  background: none;
  min-height: 84px;
  z-index: 999;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.navbar-nav .nav-link {
  position: relative;
  display: block;
  font-size: 16px;
  color: #fff;
  padding: 7px 0px;
  font-weight: 500;
  line-height: 30px;
  text-transform: none;
  opacity: 1;
  transition: all 300ms ease;
}

.navbar-nav .nav-link:hover {
  color: #f36b2b;
  text-decoration: none;
  opacity: 1;
}

.navbar-nav .nav-link.active {
  color: rgb(255 149 0);
  text-decoration: none;
  opacity: 1;
}

.is-sticky {
  position: fixed;
  top: 0;
  background: #0000001f;
  transition: all 300ms ease;
}

.theme-btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* margin-left: 20px; */
  line-height: 24px;
  padding: 9px 18px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(118.27deg, #f05020 0%, #f9bb48 100%);
  box-shadow: 0px 15px 30px #f0f0f02b;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: none;
}

/* .theme-btn:hover {
  color: #48DCA8;
  background: none;
  border-color: #48DCA8;
} */

.error-text {
  text-align: end;
  margin-top: 6px;
  margin-right: 30px;
}

.theme-btn:disabled {
  color: #fff;
  background: linear-gradient(118.27deg, #f050208a 0%, #f9bb4894 100%);
  border-color: #fff;
  cursor: not-allowed;
}

.banner-section {
  position: relative;
  /* background: #005C97; */
  /* background: -webkit-gradient(linear, left top, right top, from(#363795), to(#005C97)); */
  /* background: linear-gradient(90deg, #363795, #005C97); */
  padding: 180px 0 60px;
}

.banner-section::before {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
}

.banner-section .bg-layer {
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  background-image: url(/static/media/banner-bg-5.2473de3e.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.banner-section h1 {
  font-size: 55px;
  color: #fff;
}

.banner-section h4 {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  color: #ffffff;
  margin: 15px 0;
}

.banner-section h4 img {
  width: 40px;
  height: 40px;
  margin-left: 5px;
}

.banner-section h4 span {
  margin-left: 10px;
}

.stake-box {
  position: relative;
  padding: 20px 30px;
  border-radius: 20px;
  background: #ffff;
  box-shadow: 10px 10px 1px 0 #93918c;
}

.stake-box .all-info {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}

.stake-box .all-info .label-1 {
  font-size: 18px;
  text-align: left !important;
  line-height: 20px;
  color: #000;
  text-transform: capitalize;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 15px;
  letter-spacing: 0.8px;
}

.stake-box .all-info .credit-stacked {
  font-size: 22px;
  text-align: left !important;
  line-height: 25px;
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  width: 100%;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.stake-box .all-info .credit-stacked span {
  font-size: 13px;
  font-weight: normal;
  margin-left: 5px;
}

.calculator-section {
  padding: 100px 0;
  position: relative;
}

.calculator-section .curve-left {
  position: absolute;
  left: 0;
  top: -30px;
}

.calculator-section .curve-right {
  position: absolute;
  right: 0;
  top: -30px;
}

.calculator-section .calc-box {
  position: relative;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.53);
}

.calculator-section .calc-box h3 {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
  color: #ffffff;
}

.calculator-section .calc-box p {
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
}

.calculator-section .calc-box .add-liquidity {
  background-color: #e6e3ee;
  border-radius: 11px;
  padding: 1.6px;
}

.calculator-section .calc-box .add-liquidity .content {
  padding: 18px 4px 0px 0px;
}

.calculator-section .calc-box .add-liquidity .content .labels {
  width: 30%;
  padding: 0px 24px 0px 24px;
  background-color: #e6e3ee;
}

.calculator-section .calc-box .add-liquidity .content .labels1 {
  width: 70%;
  padding: 0px 24px 0px 24px;
  background-color: #e6e3ee;
}

.calculator-section .calc-box .add-liquidity .content .labels h6 {
  font-size: 14px;
  text-align: center;
  color: #000000;
  font-weight: 500;
  margin: 0;
}

.calculator-section .calc-box .add-liquidity .content .labels input {
  background: #cacdd2;
  border: none;
  outline: none;
  color: #000000;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border-radius: 5px;
}

.calculator-section .calc-box .add-liquidity .content .labels1 input {
  background: #cacdd2;
  border: none;
  outline: none;
  color: #000000;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  border-radius: 5px;
}

.calculator-section .calc-box .add-liquidity .content .labels .input-button {
  background: #cacdd2;
  border: none;
  color: #3867d0;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
}

.calculator-section .calc-box .add-liquidity .content .labels1 .input-button {
  background: #cacdd2;
  border: none;
  color: #3867d0;
  font-size: 15px;
  font-weight: 500;
  margin-left: 5px;
}

.calculator-section .calc-box .plus-sys {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin: 10px 0;
}

.calculator-section .calc-box .add-liquidity-2 {
  background-color: #e6e3ee;
  border-radius: 11px;
  padding: 1.6px;
}

.calculator-section .calc-box .add-liquidity-2 p {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  color: #000000;
  padding: 15px;
  line-height: 20px;
  margin: 0;
}

.calculator-section .btn-box {
  border: 2px solid #252525;
  padding: 9px;
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  margin-left: 3px;
  border-radius: 10px;
  /* margin-bottom: 16px; */
  background-color: #e6e3ee;
  margin-top: 30px;
}

.btn-box.active {
  border: 2px solid #ffffff !important;
  color: #ffffff;
  /* background: linear-gradient(to right, #0083B0, #00B4DB); */
  background-image: linear-gradient(118.27deg, #f05020 0%, #f9bb48 100%);
  /* padding: 5px; */
  /* border-radius: 7px; */
}

.btn-box:hover {
  border: 2px solid #ffffff !important;
  color: #ffffff;
  /* background: linear-gradient(to right, #0083B0, #00B4DB); */
  background-image: linear-gradient(118.27deg, #f05020 0%, #f9bb48 100%);
  /* padding: 5px; */
  /* border-radius: 7px; */
}

.calculator-section .calc-box h4 {
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  line-height: 20px;
  margin-top: 15px;
  margin-bottom: 0;
}

.details-section {
  padding: 50px 0;
  position: relative;
}

.details-section .details-box {
  position: relative;
  padding: 30px 30px;
  border-radius: 20px;
  box-shadow: inset 0px 0px 0px 6px #3d3a3a;
  margin-bottom: 30px;
  background: #f0f0f0;
  color: #000;
  text-align: center;
}

.details-section .details-box:hover {
  background: none;
  color: #fff;
  box-shadow: inset 0px 0px 0px 6px #fff;
}

.details-section .details-box:hover h4 {
  background: none;
  color: #fff;
}

.details-section .details-box .title {
  font-size: 18px;
  text-align: center;
  /* color: #000; */
  line-height: 25px;
  font-weight: normal;
  margin-bottom: 20px;
}

.details-section .details-box h4 {
  font-size: 18px;
  text-align: center;
  /* color: #000; */
  line-height: 20px;
  font-weight: 500;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.details-section table {
  border-color: #f7a33f;
}

.details-section table tr th,
.details-section table tr td {
  text-align: center;
  color: #fff;
}

footer {
  position: relative;
  padding: 0 0;
  text-align: center;
}

footer .inner {
  position: relative;
  padding: 40px 0;
  line-height: 30px;
  /* border-top: 1px solid #ffff; */
}

footer .inner .copyright {
  position: relative;
  line-height: 30px;
  color: #E0E0E0;
}

.faq-section {
  padding: 150px 0;
  position: relative;
}

.faq-section .bg-circles {
  position: absolute;
  right: 0px;
  top: -85px;
  animation-name: zoom-fade;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: zoom-fade;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.accordion-button {
  background: linear-gradient(to right, #0083B0, #00B4DB);
  color: #fff;
  padding: 10px 15px;
}

.accordion-button:not(.collapsed) {
  color: #fff;
}

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.modal-header {
  background: linear-gradient(118.27deg, #f05020 0%, #f9bb48 100%);
}

.btn-connect {
  width: 100%;
  border: 3px solid #10122d;
  cursor: pointer;
  height: 60px;
  margin: 20px auto;
  display: flex;
  padding: 5px;
  background: #fff;
  align-items: center;
  padding-left: 30px;
  border-radius: 50px;
  padding-right: 30px;
  justify-content: space-between;
}

.btn-connect .connect {
  color: #10122d;
  font-size: 20px;
  font-style: normal;
  line-height: 20px;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0;
}

.btn-connect img {
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 768px) {
  .navbar-nav:last-child {
    margin-bottom: 10px;
  }

  .navbar-toggler {
    border-color: #fff;
    color: #fff;
  }

  .main-header {
    background: #000;
  }

  .banner-section h1 {
    font-size: 44px;
  }

  .calculator-section .calc-box {
    padding: 10px;
  }

  .calculator-section .calc-box .add-liquidity-2 .btn-box {
    margin-left: 5px;
  }

  .curve-left {
    display: none;
  }

  .calculator-section .calc-box .add-liquidity .content .labels1 {
    /* width: 70%; */
    padding: 0px 10px 0px 10px;
    /* background-color: #e6e3ee; */
  }
}

/*# sourceMappingURL=Style.css.map */

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: none;
}


.claim-btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* margin-left: 20px; */
  line-height: 24px;
  padding: 4px 12px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(118.27deg, #f05020 0%, #f9bb48 100%);
  box-shadow: 0px 15px 30px #f0f0f02b;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: none;
}

.claim-btn:disabled {
  color: #fff;
  background: linear-gradient(118.27deg, #f050208a 0%, #f9bb4894 100%);
  border-color: #fff;
  cursor: not-allowed;
}

.highlight-span {
  font-size: 18px;
  color: rgb(255 165 38);
}

.stake-box .all-info .credit-stacked .highlight-span{
  font-size: 23px;
}

.text1 {
  text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
}


.table-hover>tbody>tr:hover>* {
  color: #f8b144;
}

.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #ffa526;
    border-radius: 10px;
    top: 0;
}

.labels h6{
  color: #fff;
}

.theme-btn1 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  /* margin-left: 20px; */
  line-height: 24px;
  padding: 5px 11px !important;
  color: #ffffff;
  font-weight: 600 !important;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  background-image: linear-gradient(118.27deg, #f05020 0%, #f9bb48 100%);
  box-shadow: 0px 15px 30px #f0f0f02b;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: none;
}

.table>tbody {
  vertical-align: middle;
}
